import { FC, useEffect } from 'react';
import styled from 'styled-components';
import { useAppDispatch, useAppSelector } from '../config/store';
import useBoolean from '../hooks/useBoolean';
import Modal from '../components/modals/modal/Modal';
import { removeError } from '../slices/ErrorSlice';
import Button from './form/Button';

interface ErrorsProps {
  className?: string;
}

const Errors: FC<ErrorsProps> = ({ className }) => {
  const dispatch = useAppDispatch();
  const errorState = useAppSelector((state) => state.error);
  const [isOpen, open, close] = useBoolean(false);

  useEffect(() => {
    open();
  }, [errorState.queue?.[0]]);

  if (!errorState.queue.length) {
    return null;
  }

  const handleClose = () => {
    close();
    setTimeout(() => {
      dispatch(removeError());
    }, 300);
  };

  return (
    <Modal isOpen={isOpen} close={handleClose} className={className} closeForce>
      <h2>{errorState.queue[0].title}</h2>

      <div className="errors__description">
        {(Array.isArray(errorState.queue[0].description)
          ? errorState.queue[0].description
          : [errorState.queue[0].description]
        ).map((description, i, arr) =>
          typeof description === 'string' ? (
            <p dangerouslySetInnerHTML={{ __html: description }} />
          ) : (
            description
          )
        )}
      </div>
      <div className="errors__buttons">
        <Button onClick={handleClose}>ZAVRIT</Button>
      </div>
    </Modal>
  );
};

export default styled(Errors)`
  .modal__content {
    padding: 2.4rem;
    max-width: 48rem;

    .errors__description {
      flex: 1;

      p {
        margin-bottom: 0.8rem;
      }
    }

    .errors__buttons {
      display: flex;
      justify-content: flex-end;
    }
  }
`;
