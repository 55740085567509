import { ComponentProps, FC } from 'react';
import styled, { css } from 'styled-components';
import { color, colors, ColorTheme } from '../../styles/global';

type ButtonShow = 'fill' | 'outline';

interface IconButtonProps extends ComponentProps<'button'> {
  colorTheme?: ColorTheme;
  show?: ButtonShow;
}

const IconButton: FC<IconButtonProps> = ({
  colorTheme,
  show = 'outline',
  children,
  ...props
}) => (
  <button type="button" {...props}>
    {children}
  </button>
);

export default styled(IconButton)`
  padding: 0.8rem;
  border-radius: 50%;
  cursor: pointer;
  border: none;
  outline: none;
  background-color: transparent;
  width: 4rem;
  height: 4rem;
  display: flex;
  justify-content: center;
  align-items: center;

  ${({ show, colorTheme }) =>
    show === 'fill' &&
    css`
      background-color: ${`${color(colorTheme)}`};
      border-radius: 4px;
    `}

  &:hover {
    ${({ colorTheme, show }) =>
      show === 'outline'
        ? css`
            background-color: ${`${color(colorTheme)}18`};
          `
        : css`
            background-color: ${`${color(colorTheme)}CC`};
          `};
  }

  svg {
    width: 2.4rem;
    height: 2.4rem;

    path {
      fill: ${({ show }) =>
        show === 'fill' ? colors.fontTertiary : colors.fontPrimary};
    }
  }

  &:disabled {
    cursor: not-allowed;

    ${({ show }) =>
      show === 'outline'
        ? css`
            background-color: transparent;
          `
        : css`
            background-color: #eeeeee;
            border: 1px solid #e0e2e3;
          `};

    &:hover {
      ${({ show }) =>
        show === 'outline'
          ? css`
              background-color: transparent;
            `
          : css`
              background-color: #eeeeee;
            `};
    }

    svg {
      path {
        fill: #c4c4c4;
      }
    }
  }
`;
