import { ReactNode } from 'react';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ToastType, ToastTypeType } from '../types/ToastType';

export interface ToastsState {
  queue: ToastType[];
}

const initialState: ToastsState = {
  queue: [],
};

const ToastsSlice = createSlice({
  name: 'toasts',
  initialState,
  reducers: {
    addToast: (
      state,
      {
        payload: { message, type = ToastTypeType.Success, time = 3000 },
      }: PayloadAction<{
        message: ReactNode;
        type?: ToastTypeType;
        time?: number;
      }>
    ) => {
      state.queue = [...state.queue, { message, type, time }];
    },
    removeToast: (state) => {
      state.queue = state.queue.slice(1);
    },
  },
});

export const { addToast, removeToast } = ToastsSlice.actions;

export default ToastsSlice.reducer;
