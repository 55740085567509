import React, { FC, useEffect, useState } from 'react';
import classNames from 'classnames';
import { ToastType, ToastTypeType } from '../../types/ToastType';
import { useAppDispatch, useAppSelector } from '../../config/store';
import { removeToast } from '../../slices/ToastsSlice';

import './toasts.scss';
import ErrorIcon from '../icons/ErrorIcon';
import SuccessIcon from '../icons/SuccessIcon';

interface ToastsProps {}

enum ToastState {
  SlideIn = 'slide-in',
  Visible = 'visible',
  SlideOut = 'slide-out',
  Hidden = 'hidden',
}

const animationTimeInMs = 400;

const Toasts: FC<ToastsProps> = () => {
  const dispatch = useAppDispatch();
  const toastStore = useAppSelector((state) => state.toasts);
  const [toast, setToast] = useState<ToastType | null>(null);
  const [state, setState] = useState<ToastState>(ToastState.Hidden);

  useEffect(() => {
    const tmp = toastStore.queue[0] || null;
    setToast(tmp);
    if (tmp) {
      setState(ToastState.SlideIn);
      setTimeout(() => setState(ToastState.Visible), animationTimeInMs);
      setTimeout(
        () => setState(ToastState.SlideOut),
        tmp.time + animationTimeInMs
      );
      setTimeout(() => {
        setState(ToastState.Hidden);
        dispatch(removeToast());
      }, tmp.time + 2 * animationTimeInMs);
    }
  }, [toastStore.queue.length]);

  return (
    <div
      className={classNames('toasts', state)}
      style={{ transition: `all ${animationTimeInMs}ms` }}
    >
      {toast && (
        <div className={classNames('text', toast.type)}>
          {toast.type === ToastTypeType.Success && <SuccessIcon />}
          {toast.type === ToastTypeType.Failed && <ErrorIcon />}
          {toast.message}
        </div>
      )}
    </div>
  );
};

export default Toasts;
