import styled from 'styled-components';

const Backdrop = styled.div`
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 10000;
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: fixed;
  display: none;
`;

export default Backdrop;
