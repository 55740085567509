import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios';
import { useDispatch } from 'react-redux';
import { useState } from 'react';
import useBoolean from './useBoolean';
import { signOut } from '../slices/AuthSlice';
import { isSerializable } from '../utils/JsonUtils';
import { buildUrl } from '../utils/RouterUtils';
import axiosInstance from '../config/axios';
import { getItem } from '../utils/LocalStorageUtils';

export enum AxiosMethod {
  GET = 'get',
  POST = 'post',
  PUT = 'put',
  PATCH = 'patch',
  DELETE = 'delete',
}

export interface UseAxiosData<TData, TError> {
  response: AxiosResponse<TData> | undefined;
  data: TData | undefined;
  error: Error | AxiosError<TError> | undefined;
  errorData: TError | undefined;
  isLoading: boolean;
  isFetched: boolean;
  reset: (keepData?: boolean) => void;
  resetAndRefetch: (requestData?: any) => void;
}

const useLazyAxios = <TData = any, TError = any>(
  url: string,
  method: AxiosMethod = AxiosMethod.GET,
  config: AxiosRequestConfig & {
    shouldCatchError?: boolean;
    shouldCatchAuth?: boolean;
  } = {}
): [(requestData?: any) => void, UseAxiosData<TData, TError>] => {
  const dispatch = useDispatch();
  const { shouldCatchError, shouldCatchAuth, ...params } = {
    ...config,
    shouldCatchAuth:
      config.shouldCatchAuth === undefined ? true : config.shouldCatchAuth,
    shouldCatchError:
      config.shouldCatchError === undefined ? true : config.shouldCatchError,
  };

  const [response, setResponse] = useState<AxiosResponse<TData> | undefined>(
    undefined
  );
  const [data, setData] = useState<TData | undefined>(undefined);
  const [error, setError] = useState<Error | AxiosError<TError> | undefined>(
    undefined
  );
  const [errorData, setErrorData] = useState<TError | undefined>(undefined);
  const [isLoading, enableLoading, disableLoading] = useBoolean(true);
  const [isFetched, enableIsFetched, disableIsFetched] = useBoolean(false);

  const logout = () => {
    if (shouldCatchAuth) {
      axiosInstance.request({
        method: AxiosMethod.POST,
        url: '/auth/logout',
      });
      dispatch(signOut());
      disableLoading();
    }
  };

  const fetch = (requestData: any = undefined) => {
    enableLoading();
    enableIsFetched();

    const checkedData = isSerializable(requestData)
      ? requestData
      : isSerializable(params.data)
      ? params.data
      : undefined;
    axiosInstance
      .request<TData>({
        url: method === AxiosMethod.GET ? buildUrl(url, checkedData) : url,
        method,
        ...params,
        data: checkedData,
      })
      .then((r) => {
        setResponse(r);
        setData(r.data);
        disableLoading();
      })
      .catch((e) => {
        if (shouldCatchError) {
          if (e.type && e.error) {
            if (shouldCatchAuth && e.type === 'auth') {
              logout();
            } else if (e.error.response) {
              setErrorData(e.error.response.data);
            }
          }
          setError(e.error);
        }
      })
      .finally(disableIsFetched)
      .finally(disableLoading);
  };

  const reset = () => {
    setResponse(undefined);
    setData(undefined);
    setError(undefined);
    setErrorData(undefined);
  };

  const resetAndRefetch = (requestData: any = undefined) => {
    reset();
    fetch(requestData);
  };

  return [
    fetch,
    {
      response,
      data,
      error,
      errorData,
      isLoading: isLoading && isFetched,
      isFetched,
      reset,
      resetAndRefetch,
    },
  ];
};

export default useLazyAxios;
