export const getItem = <T>(key: string): T | null => {
  const item = window.localStorage.getItem(key);
  if (!item) {
    return null;
  }
  try {
    return JSON.parse(item) as T;
  } catch {
    return null;
  }
};

export const setItem = (key: string, value: any) => {
  window.localStorage.setItem(key, JSON.stringify(value));
};

export const removeItem = (key: string) => {
  window.localStorage.removeItem(key);
};
