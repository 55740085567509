import React, { FC, Suspense } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import routes from '../../config/routes';
import PrivateRoute from './PrivateRoute';
import { useAppDispatch } from '../../config/store';
import { signIn } from '../../slices/AuthSlice';
import { getItem } from '../../utils/LocalStorageUtils';
import { useQuery } from '@tanstack/react-query';
import SwaggerApi from '../../api/SwaggerApi';
import LoadingPage from '../../pages/loading/LoadingPage';

const Router: FC = () => {
  const dispatch = useAppDispatch();

  const { isLoading } = useQuery({
    queryKey: ['me'],
    queryFn: () => SwaggerApi.auth.getMe(),
    onSuccess: (data) => {
      const accessToken = getItem<string>('accessToken');
      if (data && accessToken) {
        dispatch(
          signIn({
            ...data.data,
            tokens: {
              access_token: accessToken,
            },
          })
        );
      }
    },
  });

  return (
    <Suspense fallback={<LoadingPage />}>
      <BrowserRouter>
        <Routes>
          {routes.map((route, index) => {
            const Element = route.element;
            return (
              <Route
                path={route.path}
                key={index}
                element={
                  route.isPrivate ? (
                    <PrivateRoute isFetching={isLoading}>
                      <Element />
                    </PrivateRoute>
                  ) : (
                    <Element />
                  )
                }
              />
            );
          })}
        </Routes>
      </BrowserRouter>
    </Suspense>
  );
};

export default Router;
