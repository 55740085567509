import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { SettingResourceType } from '../api/Api';

export type SettingsState = {
  items: Record<string, string | null>;
};

const initialState: SettingsState = {
  items: {},
};

const SettingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setSettings: (state, { payload }: PayloadAction<SettingResourceType[]>) => {
      const result: Record<string, string | null> = {};
      payload.forEach((item) => {
        result[item.key!] = item.value || null;
      });
      state.items = result;
    },
  },
});

export const { setSettings } = SettingsSlice.actions;

export default SettingsSlice.reducer;
