import React, { ComponentProps, FC } from 'react';
import styled, { css } from 'styled-components';
import { ColorTheme } from '../../styles/global';
import Loader from '../../components/loader/Loader';

type ButtonSize = 'sm' | 'md';
type ButtonShow = 'full' | 'outline';

export interface ButtonProps extends ComponentProps<'button'> {
  colorTheme?: ColorTheme;
  size?: ButtonSize;
  show?: ButtonShow;
  full?: boolean;
  isLoading?: boolean;
  noWrap?: boolean;
}

const Button: FC<ButtonProps> = ({
  children,
  colorTheme,
  size,
  show,
  full = false,
  isLoading = false,
  noWrap = false,
  disabled,
  ...props
}) => (
  <button type="button" disabled={isLoading || disabled} {...props}>
    {children}
    {isLoading && (
      <div className="button__loading">
        <Loader />
      </div>
    )}
  </button>
);

export default styled(Button)`
  padding: 0.8rem 1.6rem;
  border: none;
  outline: none;
  border-radius: 4px;
  cursor: pointer;
  text-transform: uppercase;
  font-weight: bold;
  width: max-content;
  position: relative;

  ${({ noWrap }) =>
    noWrap &&
    css`
      white-space: nowrap;
    `}

  ${({ full }) =>
    full &&
    css`
      width: 100%;
    `}

  ${({ size }) =>
    size === 'sm' &&
    css`
      font-size: 1.2rem;
      padding: 0.4rem 0.8rem;
    `}

  ${({ colorTheme, show }) => css`
    ${(!colorTheme || colorTheme === 'primary') &&
    (!show || show === 'full') &&
    css`
      background-color: ${({ theme }) => theme.colors.primary};
      color: white;

      &:hover {
        background-color: ${({ theme }) => `${theme.colors.primary}CC`};
      }
    `}

    ${(!colorTheme || colorTheme === 'primary') &&
    show === 'outline' &&
    css`
      background-color: transparent;
      border: 1px solid ${({ theme }) => theme.colors.primary};
      color: ${({ theme }) => theme.colors.primary};

      &:hover {
        background-color: ${({ theme }) => theme.colors.primary};
        color: #ffffff;
      }
    `}

    ${colorTheme === 'hlavniStrana' &&
    (!show || show === 'full') &&
    css`
      background-color: ${({ theme }) => theme.colors.hlavniStrana};
      color: white;

      &:hover {
        background-color: ${({ theme }) => `${theme.colors.hlavniStrana}CC`};
      }
    `}

    ${colorTheme === 'hlavniStrana' &&
    show === 'outline' &&
    css`
      background-color: transparent;
      border: 1px solid ${({ theme }) => theme.colors.hlavniStrana};
      color: ${({ theme }) => theme.colors.hlavniStrana};

      &:hover {
        background-color: ${({ theme }) => theme.colors.hlavniStrana};
        color: #ffffff;
      }
    `}

    ${colorTheme === 'aplikace' &&
    (!show || show === 'full') &&
    css`
      background-color: ${({ theme }) => theme.colors.aplikace};
      color: white;

      &:hover {
        background-color: ${({ theme }) => `${theme.colors.aplikace}CC`};
      }
    `}

    ${colorTheme === 'aplikace' &&
    show === 'outline' &&
    css`
      background-color: transparent;
      border: 1px solid ${({ theme }) => theme.colors.aplikace};
      color: ${({ theme }) => theme.colors.aplikace};

      &:hover {
        background-color: ${({ theme }) => theme.colors.aplikace};
        color: #ffffff;
      }
    `}

    ${colorTheme === 'obchodniSubjekty' &&
    (!show || show === 'full') &&
    css`
      background-color: ${({ theme }) => theme.colors.obchodniSubjekty};
      color: white;

      &:hover {
        background-color: ${({ theme }) =>
          `${theme.colors.obchodniSubjekty}CC`};
      }
    `}

    ${colorTheme === 'obchodniSubjekty' &&
    show === 'outline' &&
    css`
      background-color: transparent;
      border: 1px solid ${({ theme }) => theme.colors.obchodniSubjekty};
      color: ${({ theme }) => theme.colors.obchodniSubjekty};

      &:hover {
        background-color: ${({ theme }) => theme.colors.obchodniSubjekty};
        color: #ffffff;
      }
    `}

    ${colorTheme === 'obchodniPripady' &&
    (!show || show === 'full') &&
    css`
      background-color: ${({ theme }) => theme.colors.obchodniPripady};
      color: white;

      &:hover {
        background-color: ${({ theme }) => `${theme.colors.obchodniPripady}CC`};
      }
    `}

    ${colorTheme === 'obchodniPripady' &&
    show === 'outline' &&
    css`
      background-color: transparent;
      border: 1px solid ${({ theme }) => theme.colors.obchodniPripady};
      color: ${({ theme }) => theme.colors.obchodniPripady};

      &:hover {
        background-color: ${({ theme }) => theme.colors.obchodniPripady};
        color: #ffffff;
      }
    `}

    ${colorTheme === 'produkty' &&
    (!show || show === 'full') &&
    css`
      background-color: ${({ theme }) => theme.colors.produkty};
      color: white;

      &:hover {
        background-color: ${({ theme }) => `${theme.colors.produkty}CC`};
      }
    `}

    ${colorTheme === 'produkty' &&
    show === 'outline' &&
    css`
      background-color: transparent;
      border: 1px solid ${({ theme }) => theme.colors.produkty};
      color: ${({ theme }) => theme.colors.produkty};

      &:hover {
        background-color: ${({ theme }) => theme.colors.produkty};
        color: #ffffff;
      }
    `}

    ${colorTheme === 'sklad' &&
    (!show || show === 'full') &&
    css`
      background-color: ${({ theme }) => theme.colors.sklad};
      color: white;

      &:hover {
        background-color: ${({ theme }) => `${theme.colors.sklad}CC`};
      }
    `}

    ${colorTheme === 'sklad' &&
    show === 'outline' &&
    css`
      background-color: transparent;
      border: 1px solid ${({ theme }) => theme.colors.sklad};
      color: ${({ theme }) => theme.colors.sklad};

      &:hover {
        background-color: ${({ theme }) => theme.colors.sklad};
        color: #ffffff;
      }
    `}

    ${colorTheme === 'evidence' &&
    (!show || show === 'full') &&
    css`
      background-color: ${({ theme }) => theme.colors.evidence};
      color: white;

      &:hover {
        background-color: ${({ theme }) => `${theme.colors.evidence}CC`};
      }
    `}

    ${colorTheme === 'evidence' &&
    show === 'outline' &&
    css`
      background-color: transparent;
      border: 1px solid ${({ theme }) => theme.colors.evidence};
      color: ${({ theme }) => theme.colors.evidence};

      &:hover {
        background-color: ${({ theme }) => theme.colors.evidence};
        color: #ffffff;
      }
    `}

    ${colorTheme === 'uzivatele' &&
    (!show || show === 'full') &&
    css`
      background-color: ${({ theme }) => theme.colors.uzivatele};
      color: white;

      &:hover {
        background-color: ${({ theme }) => `${theme.colors.uzivatele}CC`};
      }
    `}

    ${colorTheme === 'uzivatele' &&
    show === 'outline' &&
    css`
      background-color: transparent;
      border: 1px solid ${({ theme }) => theme.colors.uzivatele};
      color: ${({ theme }) => theme.colors.uzivatele};

      &:hover {
        background-color: ${({ theme }) => theme.colors.uzivatele};
        color: #ffffff;
      }
    `}

    ${colorTheme === 'gray' &&
    (!show || show === 'full') &&
    css`
      background-color: ${({ theme }) => theme.colors.gray};
      color: white;

      &:hover {
        background-color: ${({ theme }) => `${theme.colors.gray}CC`};
      }
    `}

    ${colorTheme === 'gray' &&
    show === 'outline' &&
    css`
      background-color: transparent;
      border: 1px solid ${({ theme }) => theme.colors.gray};
      color: ${({ theme }) => theme.colors.gray};

      &:hover {
        background-color: ${({ theme }) => theme.colors.gray};
        color: #ffffff;
      }
    `}
  `}

  &:disabled {
    background-color: rgba(0, 0, 0, 0.2);
    cursor: not-allowed;
  }

  .button__loading {
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.3);
    border-radius: 4px;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;

    .loader {
      width: 30px;
      height: 30px;

      div {
        border-width: 4px;
      }
    }
  }
`;
