import { Dispatch, SetStateAction, useCallback, useState } from 'react';

const useBoolean = (
  defaultValue: boolean | (() => boolean)
): [boolean, () => void, () => void, Dispatch<SetStateAction<boolean>>] => {
  const [value, setValue] = useState(defaultValue);
  return [
    value,
    useCallback(() => setValue(true), []),
    useCallback(() => setValue(false), []),
    setValue,
  ];
};

export const useBooleanWithUndefined = (
  defaultValue?: boolean | (() => boolean)
): [
  boolean | undefined,
  () => void,
  () => void,
  Dispatch<SetStateAction<boolean | undefined>>
] => {
  const [value, setValue] = useState(defaultValue);
  return [
    value,
    useCallback(() => setValue(true), []),
    useCallback(() => setValue(false), []),
    setValue,
  ];
};

export default useBoolean;
