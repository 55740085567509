import React, { FC } from 'react';

import './loader.scss';

interface LoaderProps {}

const Loader: FC<LoaderProps> = () => (
  <div className="loader">
    <div />
    <div />
    <div />
    <div />
  </div>
);

export default Loader;
