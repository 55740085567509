import React, { FC, ReactElement } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAppSelector } from '../../config/store';
import LoadingPage from '../../pages/loading/LoadingPage';

interface PrivateRouteProps {
  isFetching: boolean;
}

const PrivateRoute: FC<PrivateRouteProps> = ({ isFetching, children }) => {
  const location = useLocation();
  const { isAuthenticated } = useAppSelector((state) => state.auth);

  if (isFetching) {
    return <LoadingPage />;
  }

  if (!isAuthenticated || !children) {
    return <Navigate state={{ from: location }} to="/prihlaseni" />;
  }

  return children as ReactElement;
};

export default PrivateRoute;
