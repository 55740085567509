import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { removeItem, setItem } from '../utils/LocalStorageUtils';
import { MeResourceType } from '../api/Api';

export interface AuthState {
  isAuthenticated: boolean;
  user: MeResourceType | null;
  settings: { [key: string]: string | null };
  permissions: string[];
}

const initialState: AuthState = {
  isAuthenticated: false,
  user: null,
  settings: {},
  permissions: [],
};

const AuthSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    signIn: (state, { payload }: PayloadAction<MeResourceType>) => {
      state.isAuthenticated = true;
      state.user = payload;
      state.settings = {};
      (payload.settings || []).forEach(({ key, value }) => {
        state.settings[key] = value || null;
      });
      setItem('accessToken', payload.tokens!.access_token);
    },
    signOut: (state) => {
      state.isAuthenticated = false;
      state.user = null;
      state.settings = {};
      removeItem('accessToken');
    },
    setUser: (state, { payload }: PayloadAction<MeResourceType>) => {
      state.isAuthenticated = true;
      state.user = payload;
      state.settings = {};
      (payload.settings || []).forEach(({ key, value }) => {
        state.settings[key] = value || null;
      });
    },
    setPermissions: (state, { payload }: PayloadAction<string[]>) => {
      state.permissions = payload;
    },
  },
});

export const { signIn, signOut, setUser, setPermissions } = AuthSlice.actions;

export default AuthSlice.reducer;
