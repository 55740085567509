import { ReactNode } from 'react';

export enum ToastTypeType {
  Success = 'success',
  Failed = 'failed',
}

export type ToastType = {
  message: ReactNode;
  type: ToastTypeType;
  time: number;
};
