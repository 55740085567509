import { configureStore, ThunkAction, Action } from '@reduxjs/toolkit';
import { TypedUseSelectorHook, useDispatch, useSelector } from 'react-redux';
import AuthSlice from '../slices/AuthSlice';
import ToastsSlice from '../slices/ToastsSlice';
import SettingsSlice from '../slices/SettingsSlice';
import ErrorSlice from '../slices/ErrorSlice';

export const store = configureStore({
  reducer: {
    auth: AuthSlice,
    toasts: ToastsSlice,
    settings: SettingsSlice,
    error: ErrorSlice,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
export const useAppDispatch = () => useDispatch<AppDispatch>();
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
