import React, { FC, useEffect } from 'react';
import useLazyAxios from '../../hooks/useLazyAxios';
import GetVersionResponseType from '../../types/responses/GetVersionResponseType';

import './version.scss';

interface VersionProps {}

const Version: FC<VersionProps> = () => {
  const [getVersion, { data, errorData, isLoading }] =
    useLazyAxios<GetVersionResponseType>('/version');

  useEffect(() => {
    getVersion();
  }, []);

  if (isLoading || errorData || !data) {
    return null;
  }

  return (
    <div className="version">
      <div className="version__title">Verze aplikace:</div>
      <div className="version__text">{`${data.date} | ${data.version}`}</div>
    </div>
  );
};

export default Version;
