import * as queryString from 'query-string';

export const buildUrl = (
  url: string,
  params: { [key: string]: string | undefined } = {}
): string => {
  let replacedUrl = url.replace(/\(.*?\)/, '');
  const queryParams = { ...params };

  Object.keys(params).map((key) => {
    if (url.includes(`:${key}`)) {
      if (queryParams[key]) {
        replacedUrl = replacedUrl.replace(`:${key}`, queryParams[key]!);
      }
      delete queryParams[key];
    }
    return key;
  });
  if (Object.keys(queryParams).length) {
    return `${replacedUrl}?${queryString.stringify(queryParams)}`;
  }
  return replacedUrl;
};
